<template>
  <v-app>
    <v-main :style="{ background: $vuetify.theme.themes.dark.background }">
      <router-view class="appBar" name="appBar" />
      <router-view class="sideBar" name="sideBar" />
      <router-view class="sideBarRight" name="sideBarRight" />
      <router-view />
    </v-main>
  </v-app>
</template>

<script>
// import { mapGetters } from "vuex";

export default {
  name: "App",
  data: () => ({
    alert: false,
    body: "",
  }),
  computed: {},
  watch: {},
  created() {},
};
</script>

<style>
@import url("https://fonts.googleapis.com/css2?family=Montserrat:wght@450&display=swap");
.v-application,
.body {
  font-family: "Montserrat", sans-serif !important;
}
.v-progress-circular__underlay {
  stroke: none !important;
}
.v-dialog {
  border-radius: 25px !important;
}
.v-text-field.v-text-field--solo:not(.v-text-field--solo-flat) > .v-input__control > .v-input__slot {
    box-shadow: none !important;
}
.v-data-table__wrapper > table > thead > tr:last-child > th {
  border-bottom: none !important;
}

.theme--light.v-data-table > .v-data-table__wrapper > table > tbody > tr > td {
  border-bottom: none !important;
}

.theme--light.v-data-table > .v-data-table__wrapper > table > tbody > tr {
  box-shadow: 0px 3px 16px #0000001F;
  border-radius: 12px;
}

.v-data-table__wrapper {
  overflow-x: visible !important;
  overflow-y: visible !important;
}

.v-data-table__wrapper > table {
  border-collapse: separate !important;
  border-spacing: 0 .6rem !important;
}

.theme--light.v-data-table .v-data-footer {
  border-top: none !important;
}
</style>
